/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Inner = styled.div``;

const ControlContainer = styled.div`
  cursor: pointer;
`;

const Container = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? 'inherit' : 0)};
  overflow: hidden;
  > ${Inner} {
    opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
    transform: translate3d(0, ${({ $isOpen }) => ($isOpen ? 0 : -10)}%, 0);
    transition: transform 0.5s, opacity 1s ease-in-out;
  }
`;

type Handler = () => void;

class DrawerEvent {
  handlers: Handler[];

  constructor() {
    this.handlers = [];
  }

  publish(): void {
    this.handlers.forEach((handler) => handler());
  }

  subscribe(handler: () => void, context = handler): void {
    this.handlers.push(handler.bind(context));
  }

  unsubscribe(handler: () => void) {
    const i = this.handlers.indexOf(handler);
    if (i > -1) {
      this.handlers = this.handlers
        .slice(0, i)
        .concat(this.handlers.slice(i + 1));
    }
  }
}

function DrawerControl({
  children,
  event,
}: {
  children: JSX.Element;
  event: DrawerEvent;
}) {
  const [state, setState] = useState(true);
  return (
    <ControlContainer
      onClick={() => {
        event.publish();
        setState(false);
      }}
    >
      {state ? children : undefined}
    </ControlContainer>
  );
}

function DrawerContainer({
  children,
  event,
  open = false,
}: {
  children: JSX.Element;
  open?: boolean;
  event: DrawerEvent;
}) {
  const [state, setState] = useState(open);
  useEffect(() => {
    const handler = () => setState((prevState) => !prevState);
    event.subscribe(handler);
    return () => event.unsubscribe(handler);
  });
  return <Container $isOpen={state}>{children}</Container>;
}

function Drawer({
  children,
  header,
  open,
}: {
  children: JSX.Element;
  header: JSX.Element;
  open?: boolean;
}) {
  const event = new DrawerEvent();
  return (
    <>
      <DrawerControl event={event}>{header}</DrawerControl>
      <DrawerContainer event={event} open={open}>
        <Inner>{children}</Inner>
      </DrawerContainer>
    </>
  );
}

export default Drawer;
export { DrawerContainer, DrawerControl, DrawerEvent };
