import React from 'react';
import styled from 'styled-components';

const Container = styled.figure`
  margin: ${({ theme: { rhythm } }) => rhythm(3 / 2)} auto;
`;

const Blockquote = styled.blockquote`
  border: 1px solid
    ${({ theme: { colorScheme } }) => colorScheme.blockquote.border};
  font-size: 1rem;
  margin: 0;
  position: relative;
  width: 100%;

  ::before,
  ::after {
    color: ${({ theme: { colorScheme } }) => colorScheme.blockquote.border};
    font-family: 'Times New Roman', 'serif';
    font-size: 4em;
    font-weight: bold;
    position: absolute;
  }
  ::before {
    top: ${({ theme: { rhythm } }) => rhythm(-1 / 8)};
    left: 1rem;
    content: '“';
    text-shadow: -3px 0 hsla(55, 50%, 0%, 0.08);
  }
  ::after {
    content: '”';
    bottom: ${({ theme: { rhythm } }) => rhythm(-4 / 5)};
    right: 1rem;
    text-shadow: 3px 0 hsla(55, 50%, 0%, 0.08);
  }
`;

const CiteContainer = styled.figcaption`
  font-size: ${({ theme: { rhythm } }) => rhythm(2 / 5)};
  line-height: ${({ theme: { rhythm } }) => rhythm(1 / 2)};
  margin-top: ${({ theme: { rhythm } }) => rhythm(1 / 2)};
  padding-left: 2rem;
  padding-right: 5rem;
  cite {
    font-style: normal;
  }
`;

interface Props {
  author: string;
  children: string | JSX.Element | Array<string | JSX.Element>;
  cite: string | JSX.Element;
}

function FancyQuote({ author, children, cite }: Props) {
  return (
    <Container>
      <Blockquote>{children}</Blockquote>
      <CiteContainer>
        &#8212; {author && <>{author}, </>}
        <cite>{cite}</cite>
      </CiteContainer>
    </Container>
  );
}

export default FancyQuote;
