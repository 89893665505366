import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const copyIcon = css`
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwMjQiIHdpZHRoPSI4OTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcwNCA4OTZINjRWMzIwaDY0MHYxOTJoNjRWMTkyYzAtMzUtMjktNjQtNjQtNjRINTEyQzUxMiA1NyA0NTUgMCAzODQgMFMyNTYgNTcgMjU2IDEyOEg2NGMtMzUgMC02NCAyOS02NCA2NHY3MDRjMCAzNSAyOSA2NCA2NCA2NGg2NDBjMzUgMCA2NC0yOSA2NC02NFY3NjhoLTY0djEyOHpNMTkyIDE5Mmg2NHM2NC0yOSA2NC02NCAyOS02NCA2NC02NCA2NCAyOSA2NCA2NCAzMiA2NCA2NCA2NGg2NHM2NCAyOSA2NCA2NEgxMjhjMC0zOSAyOC02NCA2NC02NHptLTY0IDUxMmgxMjh2LTY0SDEyOHY2NHptNDQ4LTEyOFY0NDhMMzIwIDY0MGwyNTYgMTkyVjcwNGgzMjBWNTc2SDU3NnpNMTI4IDgzMmgxOTJ2LTY0SDEyOHY2NHptMzIwLTQ0OEgxMjh2NjRoMzIwdi02NHpNMjU2IDUxMkgxMjh2NjRoMTI4di02NHoiLz48L3N2Zz4K');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
`;

const EmailLink = styled.code<{ $copying: boolean; $copied: string }>`
  ${({ $copying, $copied }) => `
    cursor: pointer;
    position: relative;
    &:hover:after {
      content: '${$copying ? $copied : ` `}';
      font-size: .7rem;
      height: 2rem;
      opacity: ${$copying ? 0.9 : 0.5};
      right: -26px;
      position: absolute;
      width: 16px;
      ${!$copying ? copyIcon : ``}
    }
  `}
`;

function Email({ value, copied }: { value: string; copied: string }) {
  const [state, setState] = useState('');
  const [copying, setCopying] = useState(false);

  const onScrollHandler = () => {
    window.removeEventListener('scroll', onScrollHandler);
    setState(value);
  };

  const onClickHandler = () => {
    if (typeof navigator !== 'undefined') {
      navigator.clipboard?.writeText(value);
      setCopying(true);
      setTimeout(() => setCopying(false), 800);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', onScrollHandler);
    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  return (
    <EmailLink onClick={onClickHandler} $copying={copying} $copied={copied}>
      {state}
    </EmailLink>
  );
}

export default Email;
