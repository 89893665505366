import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import image from 'images/4w1hog.webp';

import colors from 'lib/colors/minimal';
import { useTypography } from 'providers/typography';
import Section, { BackgroundColor, TextAlign } from 'components/section';
import Separator from 'components/separator';
import Image from 'components/image';
import ArticlesList from 'components/articlesList';
import Avatar from 'components/avatar';
import AboutMe from 'components/aboutMe';
import Note from 'components/note';
import Link from 'components/link';
import * as Anchor from 'components/anchor';
import FancyQuote from 'components/fancyQuote';
import Email from 'components/email';

import Drawer, {
  DrawerContainer,
  DrawerControl,
  DrawerEvent,
} from 'components/drawer';

const Headline = styled.h3`
  ${({ theme: { width } }) => `
    margin: auto;
    text-align: center;
    @media (min-width: ${width}px) {
      width: 80%;
    }
  `}
`;

const CenterOnNarrowScreen = styled.div`
  ${({ theme: { width } }) => `
    @media (max-width: ${width}px) {
      text-align: center;
    }
    + * {
      @media (max-width: ${width}px) {
        text-align: center;
      }
    }
  `}
`;

const LiBar = styled.li<{ $width: number }>`
  list-style: none;
  margin-left: 0;
  position: relative;
  padding-bottom: 5px;

  ::before {
    background: rgb(65, 105, 225);
    bottom: 4px;
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    width: ${({ $width }) => $width * 2 || 10}%;
  }

  ::after {
    content: '${({ $width }) => $width}%';
    display: block;
    font-weight: 600;
    position: absolute;
    right: 0;
    bottom: 6px;
    text-align: right;
  }
`;

function Page() {
  const { rhythm } = useTypography();
  const drawerEvent1 = new DrawerEvent();
  return (
    <>
      <Helmet>
        <title>Jonathan Experton - Conseiller technique</title>
        <meta
          property="description"
          content="J'aide les entreprises à planifier et réussir leurs projets de développement logiciel."
        />
        <meta property="og:image" content={image} />
        <meta
          property="og:description"
          content="J'aide les entreprises à planifier et réussir leurs projets de développement logiciel."
        />
      </Helmet>
      <Section>
        <Avatar>
          <h1 style={{ marginTop: rhythm(1) }}>
            Bonjour, je m&apos;appelle Jonathan&nbsp;Experton
          </h1>
          <h2 style={{ marginTop: 0 }}>
            Je suis{' '}
            <span style={{ color: colors.C5 }}>conseiller&nbsp;technique</span>{' '}
            en&nbsp;
            <span style={{ color: colors.C5 }}>
              technologies de&nbsp;l&apos;information
            </span>
          </h2>
          <p style={{ marginBottom: 0 }}>
            J&apos;aide les entreprises à démarrer, planifier et réaliser leurs{' '}
            <strong>projets de développement logiciel</strong>.
          </p>
        </Avatar>
      </Section>
      <Section background={BackgroundColor.DARK}>
        <h2>
          Vous souhaitez démarrer un{' '}
          <span style={{ color: colors.C5 }}>
            projet de développement logiciel
          </span>
          ?
        </h2>
        <p>
          Alors, vous avez probablement déjà une bonne idée de ce que vous
          voulez. Et, peut-être avez-vous déjà une date de lancement en tête.
        </p>
        <p>
          Vous pouvez facilement exposer votre vision du projet, en démontrer la
          pertinence, et justifier pourquoi commencer dès maintenant.
          <Anchor.Note id="1" />
        </p>
        <p>Il y a cependant quelques faits que vous devriez connaître.</p>
        <Drawer
          header={
            <p style={{ marginBottom: 0 }}>
              🥱{' '}
              <span className="underline">
                Pas le temps, voir la version courte.
              </span>
            </p>
          }
        >
          <>
            <h3>Comment puis-je vous aider?</h3>
            <ul>
              <li>
                <strong>Au démarrage du projet:</strong>
                <br /> Mettre en place un processus d&apos;analyse
                d&apos;affaires, estimer les fonctionnalités et concevoir une
                solution technologique.
              </li>
              <li>
                <strong>Au moment de la planification du projet:</strong>
                <br />
                Établir la feuille de route et le plan de production du projet,
                identifier les incertitudes, et définir des stratégies
                d&apos;ajustement.
              </li>
              <li>
                <strong>Pendant la réalisation du projet:</strong>
                <br />
                Mettre en place un processus de développement logiciel itératif,
                documenter ce processus et l&apos;expliquer aux différents
                intervants.
              </li>
            </ul>
            <Image alt="How when who" src="4w1h.webp" width={50} />
          </>
        </Drawer>
      </Section>
      <Section>
        <h2>La plupart des organisations échouent à livrer leurs projets TI</h2>
        <p>De quoi parle-t-on exactement?</p>
        <FancyQuote
          author="Susan Moore"
          cite={
            <>
              <Link to="https://www.gartner.com/smarterwithgartner/it-projects-need-less-complexity-not-more-governance#:~:text=Despite%20more%20than,projects%20keep%20failing">
                IT Projects Need Less Complexity, Not More Governance
              </Link>
              . Gartner. 2015
            </>
          }
        >
          Malgré 50 ans d&apos;histoire et d&apos;innombrables méthodologies,
          conseils et livres, les projets TI échouent encore et toujours.
        </FancyQuote>
        <p>Oui, les projets TI échouent très souvent:</p>
        <FancyQuote
          author="Keith Ellis"
          cite={
            <>
              <Link to="https://web.archive.org/web/20130917021904/http://www.iag.biz/images/resources/iag%20business%20analysis%20benchmark%20-%20full%20report.pdf">
                Business Analysis Benchmark.
              </Link>{' '}
              IAG, 2008
            </>
          }
        >
          68% des entreprises ne réussiront probablement pas leurs projets.
        </FancyQuote>
        <Drawer
          header={
            <p>
              🤔{' '}
              <span className="underline">
                C&apos;est pas un peu pessimite? Dites-m&apos;en plus...
              </span>
            </p>
          }
        >
          <>
            <FancyQuote
              author="Patrick Forth, Tom Reichert, Romain de Laubier, and Saibal Chakraborty"
              cite={
                <>
                  <Link to="https://www.bcg.com/publications/2020/increasing-odds-of-success-in-digital-transformation">
                    Flipping the Odds of Digital Transformation Success
                  </Link>
                  . BCG, 2020
                </>
              }
            >
              70% des transformations numériques n&apos;atteignent pas leurs
              objectifs, souvent avec de fortes conséquences.
            </FancyQuote>

            <p>Ces échecs ne sont pas sans conséquence:</p>
            <FancyQuote
              author="Bent Flyvbjerg and Alexander Budzier"
              cite={
                <>
                  <Link to="https://hbr.org/2011/09/why-your-it-project-may-be-riskier-than-you-think">
                    Why Your IT Project May Be Riskier Than You Think
                  </Link>
                  . HBR, 2011
                </>
              }
            >
              Au moins un projet sur six que nous avons étudié était un cygne
              noir, avec en moyenne, un dépassement de coût de 200%, et un
              dépassement de l&apos;échéancier de presque 70%.
            </FancyQuote>
            <p>
              Lorsque les dépassements de coût et d&apos;échéanciers deviennent
              incontrôlables, les conséquences aussi:
            </p>
            <FancyQuote
              author="Michael Bloch, Sven Blumberg, and Jürgen Laartz"
              cite={
                <>
                  <Link to="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/delivering-large-scale-it-projects-on-time-on-budget-and-on-value#:~:text=17%20,existence%20of%20the%20company">
                    Delivering large-scale IT projects on time, on budget, and
                    on value
                  </Link>
                  . McKinsey, 2012
                </>
              }
            >
              17% des projets TI se passent tellement mal qu&apos;ils peuvent
              aller jusqu&apos;à menacer l&apos;existence même de
              l&apos;entreprise.
            </FancyQuote>
          </>
        </Drawer>
        <p>Ce ne sont pas seulement les projets TI:</p>
        <FancyQuote
          author="KPMG, AIPM and IPMA"
          cite={
            <>
              <Link to="https://www.ipma.world/assets/PM-Survey-FullReport-2019-FINAL.pdf#page=7">
                The future of project management
              </Link>
              . 2019
            </>
          }
        >
          <ul>
            <li>
              81% des organisations échouent à régulièrement livrer avec succès
              leurs projets.
            </li>
            <li>70% ne pourront probablement pas respecter leur échéancier.</li>
            <li>64% ne pourront probablement pas respecter leur budget.</li>
            <li>
              56% ne livrerons probablement pas leur projet selon
              l&apos;ambition et et les objectifs de départ.
            </li>
          </ul>
        </FancyQuote>
        <Separator />
        <h2>
          Les principales causes menant à l&apos;échec d&apos;un projet sont
          connues
        </h2>
        <p>
          En 2009, un rapport de l&apos;IDC
          <Anchor.Note id="2" /> soulignait un fait intéressant à propos des
          projets TI qui échouent:
        </p>
        <FancyQuote
          author="Joseph&nbsp;Gulla"
          cite={
            <>
              <Link to="https://web.archive.org/web/20201019111214/https://ibmsystemsmag.com/Power-Systems/2/2012/7-reasons-it-projects-fail#:~:text=By%20categorizing%20documented,at%203%20percent">
                7 Reasons IT Projects Fail
              </Link>
              . IBM&nbsp;Systems, 2012
            </>
          }
        >
          Étonnamment, les difficultés techniques sont le facteur le moins
          souvent cité, dans 3% des cas.
        </FancyQuote>
        <p>
          On pourrait penser que les projets techniques échouent en raison de
          problématiques techniques, ou par manque de compétences techniques,
          mais c&apos;est rarement le cas.
        </p>
        <p>
          Selon le rapport{' '}
          <Link to="https://www.pmi.org/-/media/pmi/documents/public/pdf/learning/thought-leadership/pulse/pmi-pulse-2021-appendix.pdf#page=11">
            Pulse of the Profession 2021
          </Link>{' '}
          du PMI, les premières raisons pour lesquelles un projet échoue sont:{' '}
        </p>
        <DrawerContainer event={drawerEvent1} open>
          <ul>
            <LiBar $width={40}>
              Mauvaise analyse des exigences d&apos;affaires
            </LiBar>
            <LiBar $width={33}>Mauvaise planification initiale</LiBar>
            <LiBar $width={27}>Gestion des risques inefficace</LiBar>
            <LiBar $width={24}>Mauvaise gestion du changement</LiBar>
            <LiBar $width={23}>Mauvaise gestion des dépendances</LiBar>
          </ul>
        </DrawerContainer>
        <DrawerContainer event={drawerEvent1}>
          <ul>
            <LiBar $width={41}>
              Changement dans les priorités de l&apos;organisation
            </LiBar>
            <LiBar $width={40}>
              Mauvaise analyse des exigences d&apos;affaires
            </LiBar>
            <LiBar $width={38}>Changement dans les objectifs du projet</LiBar>
            <LiBar $width={33}>Mauvaise planification initiale</LiBar>
            <LiBar $width={28}>Mauvaise communication</LiBar>
            <LiBar $width={28}>
              Mauvaise définition des objectifs du projet
            </LiBar>
            <LiBar $width={27}>Gestion des risques inefficace</LiBar>
            <LiBar $width={25}>
              Porteurs du projet insuffisamment impliqués
            </LiBar>
            <LiBar $width={24}>Mauvaise gestion du changement</LiBar>
            <LiBar $width={23}>Mauvaise gestion des dépendances</LiBar>
            <LiBar $width={20}>Ressources insuffisantes ou surutilisées</LiBar>
            <LiBar $width={20}>
              Manque d&apos;expérience dans la gestion de projet
            </LiBar>
            <LiBar $width={6}>Autres</LiBar>
            <li style={{ listStyle: 'none', marginLeft: 0 }}>
              <p>
                <small>
                  Q: Quelles sont les causes principales de l&apos;échec
                  d&apos;un projet? (% sélectionné)
                </small>
              </p>
            </li>
          </ul>
        </DrawerContainer>
        <DrawerControl event={drawerEvent1}>
          <p>
            🧐 <span className="underline">Voir la liste complète</span>
          </p>
        </DrawerControl>
        <p>
          Pour réussir, il y a donc plusieurs pistes à explorer avant
          d&apos;ajouter des compétences techniques à l&apos;équipe.
        </p>
      </Section>

      <Section background={BackgroundColor.DARK} textAlign={TextAlign.CENTER}>
        <p>
          Beaucoup d&apos;équipes de développement logiciel pourraient vous le
          dire:
        </p>
        <Headline>
          Les projets techniques n&apos;échouent pas à cause de problématiques
          techniques.
        </Headline>
      </Section>

      <Section>
        <h2>
          Alors, comment puis-je vous aider en temps que{' '}
          <span style={{ color: colors.C5 }}>conseiller&nbsp;technique</span>?
        </h2>
        <p>
          <em>
            D&apos;autant plus que, finalement, les problématiques sont rarement
            techniques.
          </em>
        </p>
        <p>
          Pendant les 13 dernières années, j&apos;ai travaillé avec de
          nombreuses technologies frontend, backend, mobile et cloud, sur de
          nombreux projets variés, avec des budgets allant de quelques milliers
          jusqu&apos;à plusieurs millions de&nbsp;dollars.
        </p>
        <h4 style={{ marginTop: 0 }}>
          En tant que conseiller technique, je peux vous aider à démarrer,
          planifier, et réaliser votre projet de développement logiciel avec
          succès.
        </h4>
        <Separator />
        <h3>1. Au démarrage du projet</h3>
        <p>
          Pour bâtir le succès de votre projet dès la phase de démarrage, je
          peux vous aider à:
        </p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Mettre en place un processus d&apos;analyse d&apos;affaires et de
              définition des spécifications fonctionnelles exhaustif.
            </p>
            <p>
              Les entreprises qui réussissent leurs projets sont celles qui
              utilisent l&apos;analyse d&apos;affaires comme un processus de
              découverte des exigences dès la phase de démarrage de leurs
              projets.
            </p>
            <FancyQuote
              author="Keith Ellis"
              cite={
                <>
                  <Link to="https://web.archive.org/web/20130917021904/http://www.iag.biz/images/resources/iag%20business%20analysis%20benchmark%20-%20full%20report.pdf">
                    Business Analysis Benchmark.
                  </Link>{' '}
                  IAG, 2008
                </>
              }
            >
              Si la qualité des compétences et processus d&apos;analyse
              d&apos;affaires [de votre entreprise] est seulement MOYENNE, ce
              manque d&apos;excellence consommera approximativement 41.5% du
              budget de développement logiciel sur les projets stratégiques.
            </FancyQuote>
            <p>
              Ci-dessous, un exemple de document de travail produit lors
              d&apos;une phase de définition des spécifications fonctionnelles:
            </p>
            <Image
              alt="Miro chart"
              caption="Modélisation du parcours utilisateur à travers les interfaces d'une application mobile"
              src="mapping.webp"
              width={80}
            />
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Estimer la complexité des fonctionnalités et concevoir une
              solution technologique adaptée au budget, à l&apos;échéancier, et
              à la capacité de l&apos;équipe de développement.
            </p>
            <p>
              En partant de spécifications fonctionnelles générales, il est
              possible de faire les premières estimations et envisager le cadre
              technologique.
            </p>
            <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764516780541265&cot=14">
              <Image
                alt='Méthode "t-shirt sizing"'
                caption='Comment estimer avec la méthode "t-shirt sizing"'
                src="tshirt-sizing-estimates.webp"
                width={90}
              />
            </Link>
          </li>
        </ul>
        <Separator />
        <h3>2. Au moment de la planification du projet</h3>{' '}
        <p>
          Une fois que les spécifications fonctionnelles générales et les
          premières estimations sont connues, les ressources nécessaires à la
          réalisation du projet se précisent. Pendant la phase de planification,
          je peux vous aider à:
        </p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Établir la feuille de route et le plan de production du projet en
              tenant compte des ressources disponibles et des dépendances
              techniques.
            </p>
            <p>
              Cette étape permet d&apos;établir les besoins de l&apos;équipe de
              développement et les étapes de livraison.
            </p>
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Identifier les risques, les incertitudes, et définir des
              stratégies d&apos;ajustement de la feuille de route.
            </p>
            <p>
              Si les étapes de livraison ne peuvent pas être respectées, par
              manque de ressource ou à cause de difficultés techniques, les
              conséquences doivent être anticipées.
            </p>
          </li>
        </ul>
        <Separator />
        <h3>3. Pendant la réalisation du projet</h3>
        <p>Au cours de votre projet, deux choses vont arriver:</p>
        <ul>
          <li>
            Certaines personnes vont changer d&apos;avis et vouloir modifier ce
            qui a été décidé et planifié.
          </li>
          <li>
            Certaines personnes vont quitter le projet et d&apos;autres vont
            s&apos;y joindre.
          </li>
        </ul>
        <p>
          Je peux vous aider à intégrer ces deux éléments de la manière
          suivante:
        </p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Mettre en place un processus itératif de développement logiciel
              qui intègre l&apos;analyse d&apos;affaires et la planification.
            </p>
            <p>
              Cela afin d&apos;adapter en continu le périmètre fonctionnel et la
              planification du projet, et afin d&apos;intégrer les
              apprentissages issus des itérations précédentes.
            </p>
            <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764520753890444&cot=14">
              <Image
                alt="Cycle de développement logiciel"
                caption="Cycle de développement logiciel simplifié"
                src="sdlc-circle.webp"
                width={90}
              />
            </Link>
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Définir, documenter, et expliquer le processus de développement
              logiciel aux différents intervenants.
            </p>
            <p>
              Pour aligner les pratiques et les processus de l&apos;ensemble des
              équipes, et intégrer plus rapidement les nouveaux membres.
            </p>
          </li>
        </ul>
        <p>
          <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764516388272898&cot=14">
            <Image
              alt="Tableau Miro"
              caption="Cycle de développement logiciel"
              src="sdlc.webp"
              width={90}
            />
          </Link>
        </p>
      </Section>
      <Section background={BackgroundColor.DARK} minHeight={5}>
        <Headline>
          Je peux vous aider à établir un plan d&apos;action et naviguer à
          travers la complexité d&apos;un projet de développement logiciel.
        </Headline>
      </Section>

      <Section>
        <AboutMe>
          <CenterOnNarrowScreen>
            <h3 style={{ marginTop: rhythm(1) }}>Me contacter</h3>
            <p>
              Si vous souhaitez en savoir plus sur la manière dont je peux vous
              aider, contactez-moi sur{' '}
              <Link to="https://www.linkedin.com/in/jexperton/">LinkedIn</Link>{' '}
              ou écrivez-moi à{' '}
              <Email value="jonathan.experton@gmail.com" copied="copié!" />
            </p>
          </CenterOnNarrowScreen>
        </AboutMe>
        <Separator />
        <h3>
          Mes derniers articles sur les technologies de l&apos;information et le
          développement logiciel
        </h3>
        <ArticlesList limit={4} filter={({ promoted }) => promoted} />
        <p>
          <Link to="/en/blog">Voir d&apos;autres articles</Link>
        </p>
        <Separator />
        <Note id="1">
          Si vous avez besoin d&apos;aide pour mieux définir vos besoins et
          votre vision, mes <Link to="https://andfriends.ca/">amis</Link>{' '}
          peuvent vous aider.
        </Note>
        <Note id="2">
          Ce rapport de l&apos;IDC, &quot;Improving IT Project Outcomes by
          Systematically Managing and Hedging Risk&quot; par Dana Wiklund et
          Joseph C. Pucciarelli, est souvent cité, mais je n&apos;ai pas trouvé
          de lien public.
        </Note>
      </Section>
    </>
  );
}

export default Page;
